import React from 'react'
import './Dashboard.css'


function Dashboard() {
  return (
    <div className='body'>
      <h1 className="text-3xl font-bold underline">
      Hello world!
    </h1>
    </div>
  )
}

export default Dashboard
