import React from 'react'
import Login from '../../Components/Login/Login'

function LoginPage() {
  return (
    <div>
      <Login></Login>
    </div>
  )
}

export default LoginPage
